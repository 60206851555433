<template>
  <span class="cs-menu-center-double">
    <div > <!-- id="cs-header-menu" class="cs-header-menu-block cs-block cs-no-padding" class="cs-base-block" -->
      <div  :class="rootClasses">       
        <div class="cs-nav-primary-container navbar-expand-xl">
          <div class="container">
            <div class="cs-nav-primary">
              <a v-if="data && data.logo" href="/"><img class="cs-logo" :src="data.logo" /></a>
              <div class="toggler-wrapper">
                <NavbarToggler data-target="cs-header-primary-nav" aria-controls="cs-header-primary-nav" />
              </div>
              <div class="collapse navbar-collapse" id="cs-header-primary-nav">
                <div class="cs-nav-primary-links">
                  <template v-for="(lvl1menuItem, index) in lvl1Menu">
                    <span
                          v-if="lvl1menuItem.children && Object.keys(lvl1menuItem.children).length"
                          @click="hoverFirstLvlNav($event, `primary-${index}`, lvl1menuItem)"
                          :class="linkClasses(`primary-${index}`, 'active1LvlMenuItem', lvl1menuItem)"
                      >
                        <SingleDropDown v-if="useSingleDropDown(lvl1menuItem) && Object.keys(active1LvlMenuItem.menu).length && active1LvlMenuItem.menu.children && Object.keys(active1LvlMenuItem.menu.children).length && active1LvlMenuItem.selected == `primary-${index}`" :links="lvl1menuItem.children" />
                        {{ lvl1menuItem.title }}
                        <svg v-if="lvl1menuItem.children && Object.keys(lvl1menuItem.children).length" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M5 7.5L10 12.5L15 7.5H5Z" fill="#13314E"/>
                        </svg>
                        <MobileDropdown v-if="lvl1menuItem.children && Object.keys(lvl1menuItem.children).length && active1LvlMenuItem.selected == `primary-${index}`" :links="lvl1menuItem.children" :sub-level="1" />
                    </span>
                    <a
                      v-else
                      :href="lvl1menuItem.url"
                      :class="linkClasses(`primary-${index}`, 'active1LvlMenuItem', lvl1menuItem)"
                      @click="hoverFirstLvlNav($event, `primary-${index}`, lvl1menuItem)"
                    >
                      {{ lvl1menuItem.title }}
                      <svg v-if="lvl1menuItem.children && Object.keys(lvl1menuItem.children).length" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M5 7.5L10 12.5L15 7.5H5Z" fill="#13314E"/>
                      </svg>
                    </a>
                  </template>
                  
                </div>
                <div v-if="showInteractions" class="cs-interactions">
                  <span v-for="(button, index) in data.links" :key="`side-link-${index}`" v-show="button.login == isLoggedIn">
                    <CSButton :data="button.url" :title="button.linkText"></CSButton>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <LargeDropDown
          v-if="Object.keys(active1LvlMenuItem.menu).length && active1LvlMenuItem.menu.children && Object.keys(active1LvlMenuItem.menu.children).length && !useSingleDropDown(active1LvlMenuItem.menu)"
          :hover-second-lvl-nav="hoverSecondLvlNav"
          :link-classes="linkClasses"
          :active1-lvl-menu-item="active1LvlMenuItem"
          :active2-lvl-menu-item="active2LvlMenuItem"
        />
      </div>
    </div>
  </span>
</template>

<script>
  export default {
    name: "CSMenuCenterDouble",
    components: {
    LargeDropDown: () => import('./Utils/LargeDropDown.vue'),
    SingleDropDown: () => import('./Utils/SingleDropDown.vue'),
    NavbarToggler: () => import('./Utils/NavbarToggler.vue'),
    MobileDropdown: () => import('./Utils/MobileDropdown.vue'),
    CSButton: () => import('../CSButton.vue')
},
    data() {
      return {
        uniqueId: "id" + Date.now(),
        build: {},
        active1LvlMenuItem: {
          selected: '',
          menu: {}
        },
        active2LvlMenuItem: {
          selected: '',
          menu: {}
        }
      };
    },
    props: {
      data: {
        type: Object,
        default: () => {},
      },
    },
    methods: {
      linkClasses(key, menuToCheck, childrenCount) {
        let out = '';

        if (!this[menuToCheck]) console.log(`Failed to check for menu ${menuToCheck}, this is:`, this);
        if (this[menuToCheck]?.selected == key) out += 'active';
        if(childrenCount == 0) out += ' has-no-children' 

        return out;
      },
      hoverFirstLvlNav(event, key, lvl1menuItem) {
        const generateQuerySelector = function(el) {
          if (el.tagName.toLowerCase() == "html")
              return "HTML";
          var str = el.tagName;
          str += (el.id != "") ? "#" + el.id : "";
          if (el.className && typeof el.className == 'string') {
              var classes = el.className.split(/\s/);
              for (var i = 0; i < classes.length; i++) {
                  str += "." + classes[i]
              }
          }
          return generateQuerySelector(el.parentNode) + " > " + str;
        };

        const targetString = generateQuerySelector(event.target);

        if (targetString.indexOf('mobile-dropdown') != -1) return;

        if (lvl1menuItem.children && Object.keys(lvl1menuItem.children).length && key != this.active1LvlMenuItem.selected) {
          this.active1LvlMenuItem.selected = key;
          this.active1LvlMenuItem.menu = lvl1menuItem;

          // Select first child link with sublinks to be opened
          if (this.active1LvlMenuItem.menu?.children) {
            for (const [index, lvl2menuItem] of Object.entries(this.active1LvlMenuItem.menu.children)) {
              this.hoverSecondLvlNav(`second-lvl-${index}`, lvl2menuItem);
              break;  
            }
          }
        }else if(key == this.active1LvlMenuItem.selected){
          this.closeMenus()
        }
      },
      hoverSecondLvlNav(key, menu) {
        if (this.active2LvlMenuItem.select != key) {
          this.active2LvlMenuItem.selected = key;
          this.active2LvlMenuItem.menu = menu;
        }
      },
      closeMenus() {
        this.active1LvlMenuItem = {
          selected: '',
          menu: {}
        };
        this.active2LvlMenuItem = {
          selected: '',
          menu: {}
        };
      },
      useSingleDropDown(menu) {
        if (menu.children && Object.keys(menu.children).length) {
            for (const link of Object.entries(menu.children)) {
                if (link[1].children && Object.keys(link[1].children).length) {
                    return false;
                }
            }
        }

        return true;
      }
    },
    mounted(){
      console.log('Menu:', this);
    },
    computed: {
      showInteractions() {
        if (this.data?.links) {
          if (Array.isArray(this.data.links) && this.data.links.length) return true;

          if (typeof this.data.links == 'object' && Object.keys(this.data.links).length) return true;
        }

        return false;
      },
      rootClasses() {
        let result = {};

        if (Object.keys(this.active1LvlMenuItem.menu).length) return {'cs-submenu-active': true};

        return result;
      },
      isLoggedIn(){
        return this.data.isLoggedIn
      },
      lvl1Menu(){
        if(this.data){
          if(this.data.menu) return Object.values(this.data.menu);
          else return []
        }else return []
      }
    },
  };
</script>

<style lang="scss" scoped>
  @import "../../libs/common-styles.scss";
  @import "../../styles/main.scss";

  .cs-menu-center-double {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    margin: auto;
    font-family: $font_family;
    background-color: #00274f;
    width: 100%;

    .toggler-wrapper {
      margin-left: auto;
      height: 159px;
      display: flex;
      align-items: center;
    }

    .cs-nav-primary-container {
      position: relative;

      @media (max-width: 576px) {
        padding-left: 10px;
        padding-right: 10px;
      }

      &:after {
        content: "";
        position: absolute;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 8px;
        box-shadow: 0px 4px 4px rgba(29,140,242,0.08);
        z-index: 2;
      }
    }

    .cs-nav-primary {
      width: 100%;
      display: flex;
      align-items: center;
      padding-top: $default_margin / 2;
      padding-bottom: $default_margin / 2;



      @media (min-width: 1199px) {

        display: block !important;

        >a {
            display: flex;
            justify-content:center;
            margin-bottom:15px;
        }
    }

    .cs-logo {
        width: auto;
        height: 72px;
        object-fit: contain;
        object-position: center;
        max-width: 150px;
        margin-right: $default_margin;
      }

      .cs-nav-primary-links {
        display: flex;
        align-items: center;
        margin-right: $default_margin;
        flex: 1 0 auto;

        >a{

          &.has-no-children{
            display:flex !important;
          }
        }
        >span{
          display:flex !important;
          &.has-no-children{
            display:none !important;
          }
        }

        > * {
          color: white;
          font-size: $regular_text_size;
          margin-right: $default_margin;
          cursor: pointer;
          padding: 8px 10px;
          transition: background-color 300ms ease-out;
          border-radius: 8px;
          display: flex;
          align-items: center;
          user-select: none;
          text-decoration: none;
          outline: none;
          position: relative;

          svg {
            margin-left: 5px;
            transition-duration: 300ms;
            transition-property: transform;
            path{
              fill:white;
            }
          }

          &:hover,
          &:focus-visible,
          &:focus-within,
          &:focus {
            text-decoration: none;
            outline: none;
          }

          &:hover,
          &.active {
            background-color: $first_navmenu_action_color;
          }

          &.active {
            svg {
              transform: rotate(180deg);
              -webkit-transform: rotate(180deg);

            }
          }

          &:last-child {
            margin-right: 0px;
          }

          @media (max-width: 1198.98px) {
            width: 100%;
            margin-right: 0px;
            text-align: center;
          }
        }

        @media (max-width: 1198.98px) {
          margin-right: 0px !important;
        }
        
      }

      .cs-interactions {
        display: flex;
        margin-left: auto;
        flex: 0 1 auto;
        justify-content: flex-end;

        button,
        ::v-deep .cs-button {
          color: #fff;
          background: #00a983;
          background: linear-gradient(70deg, #00a983 0%, #2bd784 100%);
          padding: 10px 25px;
          text-align: center;
          outline: none;
          transition-property: color, background-color;
          transition-duration: 300ms;
          transition-timing-function: ease-out;
          border-radius: 40px;
          min-width: 120px;
          text-decoration: none;
          margin-right: $default_margin;



            @media (min-width: 1199px) {
                padding: 5px 20px;
            }

          &:last-child {
            margin-right: 0px;
          }
          
          &:hover,
          &:focus,
          &:focus-visible,
          &:focus-within {
            outline: none;
            background-color: $bg_light;
            color: $dark_blue;
          }
        }
      }

      @media (max-width: 1199.98px) {
        flex-wrap: wrap;

        .cs-nav-primary-links {
          flex-direction: column;
          align-items: flex-start;

          a {
            width: 100%;
            text-align: center;
            display: inline-block;
          }
        }

        .cs-interactions {
          margin: 30px 0;
          margin-left: unset;
          width: 100%;
          flex-wrap: wrap;
          margin-left: -7.5px;
          margin-right: -7.5px;
          justify-content: center;

          ::v-deep .cs-button {
            margin-left: 7.5px;
            margin-right: 7.5px;
          }
        }
      }
    }
  } 
</style>